import { ReactElement, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';

import { setUserCookies } from 'utils/cookies';
import { AuthContainer, CFContainer } from 'styles/containers';
import Input from 'views/components/Input';
import { useTranslation } from 'src/hooks/translation';
import Logo from 'views/components/Logo';
import GoogleIcon from 'public/icons/authProviders/google_icon_hdx.svg';
import { ROUTES } from 'utils/constants/routes';
import { Button, TextButton } from 'views/components/Button';
import { FieldsErrors } from 'views/components/FieldsErrors';
import SKIP_PROTECTED_ROUTES from 'utils/constants/skipProtectedRoutes';
import { IS_DEV } from 'utils/constants/common';
import { Divider } from 'views/components/Divider';
import { useIdentityProvidersStore } from 'store/auth';

const isValidUrl = (url: string) => {
  if (url?.startsWith('//')) {
    return true;
  }
  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
};

const ssoProviderIcons: Record<AuthIdentityProviderRes['alias'], JSX.Element> = {
  google: <GoogleIcon />,
};

const isProtectedRoute = (prevUrl: string, routes: string[]) => {
  // Normalize the prevUrl to ensure no trailing slash
  const normalizedUrl = prevUrl.replace(/\/$/, '');

  // Check if the normalized URL matches any route or starts with a route followed by a '/'
  return routes.some((route) => normalizedUrl === route || normalizedUrl.startsWith(`${route}/`));
};

const Login = () => {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const t = useTranslation();

  const { data: ssoProviders } = useIdentityProvidersStore();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [responseError, setResponseError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FieldValues) => {
    setResponseError({});
    setIsLoading(true);

    const { username, password, oucrqworzd, dgilavwqdn } = data;

    const newUsername = IS_DEV ? username : oucrqworzd;
    const newPassword = IS_DEV ? password : dgilavwqdn;

    try {
      const { data } = await axios.post<User>('/login', {
        username: newUsername,
        password: newPassword,
      });

      setUserCookies(data);

      await mutate(`/users/${data.uuid}`, data, false);

      const { prevUrl } = router.query;
      if (prevUrl && !isValidUrl(prevUrl as string)) {
        return router.push(
          isProtectedRoute(prevUrl as string, SKIP_PROTECTED_ROUTES) ? '/' : (prevUrl as string),
        );
      }
      setIsLoading(false);
      router.push(ROUTES.main);
    } catch (error) {
      setIsLoading(false);
      setResponseError({
        response: {
          message:
            ((error as ResponseError).error as RegularResponseError)?._error?.message ||
            t.common.error,
        },
      });
    }
  };

  const handleNavigateToRestorePassword = () => {
    router.push(ROUTES.passwordReset);
  };

  const handleNavigateToSSOProvider = useCallback(
    (alias: AuthIdentityProviderRes['alias']) => {
      const { prevUrl } = router.query;
      router.push(
        {
          pathname: window.location.origin + '/config/v1/login',
          query: {
            idp_hint: alias,
            ...(!!prevUrl && { redirect_uri: prevUrl }),
          },
        },
        undefined,
        { locale: false },
      );
    },
    [router],
  );

  const RenderSSOButtons = useCallback(
    () =>
      ssoProviders && ssoProviders?.length ? (
        <>
          <Divider middleText={t.auth.login.dividerText} />
          {ssoProviders.map(({ alias, display_name }) => (
            <Button
              key={alias}
              view="secondary"
              fullWidth
              onClick={() => handleNavigateToSSOProvider(alias)}
              icon={ssoProviderIcons[alias]}
              dataTestId={`sso-provider-${alias}-login-button`}
            >
              {t.auth.login.loginSsoBtn(display_name?.length ? display_name : alias)}
            </Button>
          ))}
        </>
      ) : null,
    [ssoProviders, t, handleNavigateToSSOProvider],
  );

  return (
    <AuthContainer>
      <CFContainer gap={44}>
        <Logo />
        <CFContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CFContainer>
              <Controller
                rules={{
                  required: t.auth.login.errors.emailRequired,
                  pattern: {
                    value: /^[\w -.]+@([\w -]+\.)+[\w -]{2,8}$/,
                    message: t.auth.login.errors.emailFormat,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    label={t.auth.login.email}
                    dataTestId="368c99aaa447ab050a88dd9461afc235c002d75cc4c3eee3f6498557da8bec46" // sha256 of: hdx-auth-email-input
                    error={!!errors.username}
                    autocomplete="off"
                  />
                )}
                control={control}
                name={IS_DEV ? 'username' : 'oucrqworzd'}
                defaultValue={''}
              />
              <Controller
                rules={{
                  required: t.auth.login.errors.passwordRequired,
                  validate: (value) => typeof value === 'string',
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    type="password"
                    label={t.auth.login.password}
                    dataTestId="e05fe34f8358ac388ccead2410bdcc37626cf9dc31b1fd54491de6e15cf5e507" //sha256 of: hdx-auth-password-input
                    autocomplete="off"
                  />
                )}
                control={control}
                name={IS_DEV ? 'password' : 'dgilavwqdn'}
                defaultValue={''}
              />
              <FieldsErrors errors={errors} />
              <FieldsErrors errors={responseError} />
              <Button
                type="submit"
                view="primary"
                fullWidth
                disabled={isLoading}
                isLoading={isLoading}
                dataTestId="59f556e9680e5a0a444fb07a587e87427a640e06dca15009cef2dfb03afc1c6b" // sha256 of: hdx-login-form-submit-button
              >
                {t.auth.login.BTN}
              </Button>
            </CFContainer>
          </form>
          <TextButton
            onClick={handleNavigateToRestorePassword}
            dataTestId="027e3b2ff335a18f0683e9ff17ffc73a78c6934bdc8cf87ac8ae81c9f4f8f333" // sha256 of: hdx-navigate-to-password-reset-button
          >
            {t.auth.login.forgotPass}
          </TextButton>
          <RenderSSOButtons />
        </CFContainer>
      </CFContainer>
    </AuthContainer>
  );
};

Login.getLayout = (page: ReactElement) => <>{page}</>;

export default Login;
